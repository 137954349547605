import React from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share"
import styled from "styled-components"

const ShareButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

const share = props => {
  return (
    <ShareButtons>
      <TwitterShareButton url={`https://jimenez.tech/blog/${props.data.slug}`}>
        <TwitterIcon size={40} />
      </TwitterShareButton>
      <FacebookShareButton url={`https://jimenez.tech/blog/${props.data.slug}`}>
        <FacebookIcon size={40} />
      </FacebookShareButton>
      <LinkedinShareButton url={`https://jimenez.tech/blog/${props.data.slug}`}>
        <LinkedinIcon size={40} />
      </LinkedinShareButton>
      <EmailShareButton url={`https://jimenez.tech/blog/${props.data.slug}`}>
        <EmailIcon size={40} />
      </EmailShareButton>
    </ShareButtons>
  )
}

export default share
