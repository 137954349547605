import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/layout"
import variables from "../components/GlobalVariables"
import styled from "styled-components"
import Title from "../components/UI/Title"
import Share from "../components/BlogItems/Share"
// import Comments from "../components/BlogItems/comments"

const LayoutStyleArticle = styled.div`
  margin-top: 8vh;
  min-height: 100vh;
  text-align: left;
`

const BlogPostContainer = styled.div`
  background-color: ${variables.greyColor};
  box-sizing: border-box;
  margin: 0 auto;
  width: 50%;
  padding: 20px;

  p {
    letter-spacing: 0.7px;
    line-height: 1.5;
    font-size: 1.4rem;
  }

  hr {
    background-color: ${variables.accentColor} solid 2px;
  }

  pre {
    background: #292929;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 1);
  }

  img {
    width: 100%;
    height: 20%;
  }

  pre {
    overflow: auto;
  }

  a {
    text-decoration: none;
    color: ${variables.accentColor};
  }

  figcaption {
    opacity: 0.6;
    text-align: center;
    font-style: italic;
  }

  @media (max-width: ${variables.mediumScreen}) {
    width: 100%;
    max-width: 920px;

    .kg-image-card {
      width: 100%;
      height: auto;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 85%;
        height: auto;
      }
    }

    @media (max-width: ${variables.smallScreen}) {
      width: 100%;
      max-width: 700px;
    }

    .kg-image-card {
      width: 100%;
      margin: 0 auto;
    }
  }
`

const AuthorBubble = styled.div`
  box-sizing: border-box;
  width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  img {
    width: 100px;
    height: 100%;
    border-radius: 50px;
    margin-right: 10px;
  }
`

const PostTag = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  p {
    border: ${variables.accentColor} 1px solid;
    color: ${variables.accentColor};
    padding: 5px 15px;
    margin: 3px;
    border-radius: 50px;
    overflow: hidden;
  }

  p:hover {
    background: ${variables.accentColor};
    color: ${variables.clearColor};
    transition: 300ms;
  }
`

const article = props => {
  const PostData = props.data.ghostPost

  const articleTags = PostData.tags.map(articleTag => {
    return <p key={articleTag.id}>#{articleTag.name}</p>
  })

  return (
    <Layout>
      <LayoutStyleArticle>
        <SEO
          title={PostData.title}
          description={PostData.excerpt}
          image={PostData.twitter_image}
          pathname={PostData.twitter_image}
        />
        <BlogPostContainer className="content">
          <p>
            <b>
              <Link to="/blog">Back to Posts</Link>
            </b>
          </p>
          <Title>{PostData.title}</Title>
          <p>
            By: {PostData.authors[0].name} | Published on: {PostData.created_at}
          </p>
          <PostTag>{articleTags}</PostTag>
          <br />
          <hr />
          <br />
          <section dangerouslySetInnerHTML={{ __html: PostData.html }} />
          <br />
          <p>Share:</p>
          <Share data={PostData} />
          <br />
          <AuthorBubble>
            <img src={PostData.authors[0].profile_image} alt="Daniel Jimenez" />

            <div className="authorBubble">
              <p>
                <b>{PostData.authors[0].name}</b>
              </p>
              <p>{PostData.authors[0].location}</p>
              <p>
                <a href="https://jimenez.tech">{PostData.authors[0].website}</a>
              </p>
            </div>
          </AuthorBubble>
          <br />
          <br />
          <br />
        </BlogPostContainer>
      </LayoutStyleArticle>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{ 
"@context": "https://schema.org", 
"@type": "Article",
"headline": "${PostData.title}",
"alternativeHeadline": "${PostData.title}",
"name": "${PostData.title}",
"thumbnailUrl": "${PostData.feature_image}",
"description": "${PostData.excerpt}",
"url": "https://jimenez.tech/${PostData.slug}",
"image": {
"@type": "ImageObject",
"url": "${PostData.feature_image}",
"width": "682",
"height": "400"
},
"author": {
"@type": "Person",
"name": "${PostData.authors[0].name}"
},
"datePublished": "${PostData.created_at}",
"dateCreated": "${PostData.created_at}",
"dateModified": "${PostData.created_at}",
"publisher": {
"@type": "Organization",
"name": "Daniel Jimenez",
"logo": {
"@context": "http://schema.org",
"@type": "ImageObject",
"author": "Daniel Jimenez",
"contentUrl": "http://jimenez.tech/logo.PNG",
"url": "http://jimenez.tech/logo.PNG",
"datePublished": "2020-02-26",
"description": "My logo",
"name": "Daniel Jimenez Logo"
}
},
"editor": "Daniel Jimenez", 
"genre": "${PostData.primary_tag.slug} tips", 
"keywords": "${PostData.tags.map(tag => tag.name).join(",")}",             
"mainEntityOfPage": "True"
}
`,
        }}
      />
    </Layout>
  )
}

export const singlePostQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      authors {
        name
        location
        id
        website
        profile_image
      }
      created_at(formatString: "MMM DD YYYY")
      feature_image
      html
      id
      slug
      title
      canonical_url
      tags {
        id
        name
      }
      primary_tag {
        slug
      }
      twitter_description
      twitter_image
      twitter_title
    }
  }
`
export default article
